import './App.css';

const GameBox = ({ img, id, title }) => {
  return <label for={id} className="GameBox">
    <div className="GameBoxInfo">
      {img && <img src={img} alt={title} />}
      <div>{title}</div>
    </div>
    <input type="checkbox" id={id} />
  </label>;
}

function App() {
  return (
    <div className="Container">
      <div className="Section">
        <div className="Header">
          <h1 className="Heading">GameBot</h1>
          <div>Logged in as <span>baytekin96</span></div>
        </div>
        <div className="Card">
          <h1 className="Heading">Sahip Olunan Oyunlar</h1>
          <div className="Description">Oyun grubuna eklenebilmek için bilgisayarında yüklü olan oyunları seç.</div>
          <div className="GameBoxContainer">
            <GameBox id="1" title="Pummel Party" img="https://images.igdb.com/igdb/image/upload/t_cover_big/co20uq.png" />
            <GameBox id="2" title="Codenames" img="https://images.igdb.com/igdb/image/upload/t_cover_big/co3wv1.png" />
            <GameBox id="3" title="Gartic Phone" img="https://images.igdb.com/igdb/image/upload/t_cover_big/co49qp.png" />
            <GameBox id="4" title="Gartic.io" img="https://images.igdb.com/igdb/image/upload/t_cover_big/co4jl3.png" />
            <GameBox id="5" title="Risk: Global Domination" img="https://images.igdb.com/igdb/image/upload/t_cover_big/co2cwk.png" />
            <GameBox id="6" title="Project Winter" img="https://images.igdb.com/igdb/image/upload/t_cover_big/co2t2u.png" />
            <GameBox id="7" title="Stick Fight: The Game" img="https://images.igdb.com/igdb/image/upload/t_cover_big/co1ucx.png" />
            <GameBox id="8" title="Tabletop Simulator" img="https://images.igdb.com/igdb/image/upload/t_cover_big/co20xe.png" />
            <GameBox id="9" title="Grand Theft Auto V" img="https://images.igdb.com/igdb/image/upload/t_cover_big/co2lbd.png" />
            <GameBox id="10" title="Rubber Bandits" img="https://images.igdb.com/igdb/image/upload/t_cover_big/co496w.png" />
            <GameBox id="11" title="Among Us" img="https://images.igdb.com/igdb/image/upload/t_cover_big/co6kqt.png" />
            <GameBox id="12" title="Blazing Sails" img="https://images.igdb.com/igdb/image/upload/t_cover_big/co2g4w.png" />
            <GameBox id="13" title="Crab Game" img="https://images.igdb.com/igdb/image/upload/t_cover_big/co46sr.png" />
            <GameBox id="14" title="Fall Guys" img="https://images.igdb.com/igdb/image/upload/t_cover_big/co4vs3.png" />
            <GameBox id="15" title="Gang Beasts" img="https://images.igdb.com/igdb/image/upload/t_cover_big/co24is.png" />
            <GameBox id="16" title="Human: Fall Flat" img="https://images.igdb.com/igdb/image/upload/t_cover_big/co2y4a.png" />
            <GameBox id="17" title="Left 4 Dead 2" img="https://images.igdb.com/igdb/image/upload/t_cover_big/co1y2f.png" />
            <GameBox id="18" title="PlateUp!" img="https://images.igdb.com/igdb/image/upload/t_cover_big/co4ty9.png" />
            <GameBox id="19" title="Overcooked!" img="https://images.igdb.com/igdb/image/upload/t_cover_big/co262g.png" />
            <GameBox id="20" title="World War Z" img="https://images.igdb.com/igdb/image/upload/t_cover_big/co1ja4.png" />
            <GameBox id="21" title="Valorant" img="https://images.igdb.com/igdb/image/upload/t_cover_big/co2mvt.png" />
            <GameBox id="22" title="Counter-Strike: Global Offensive" img="https://images.igdb.com/igdb/image/upload/t_cover_big/co6996.png" />
          </div>
          <div className="ActionContainer">
            <button className="Button">Kaydet</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
